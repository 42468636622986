$skeleton-delay: 400ms;

ngx-skeleton-loader {
  .skeleton-loader {
    border-radius: 2rem !important;
    animation: opacity ($skeleton-delay + $skeleton-delay * 0.25) ease-out;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
