@use '@angular/material' as mat;
@use 'sass:map';
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;

/***
  * Button toggle style.
  * Used as a mix between button and chip
 */
.filter-toggle {
  @include mix.gap-sm;
  border: none;

  &__button {
    @include mix.semi-bold;
    @include mix.grey-color(700);
    @include mix.grey-background(100);
    @include mix.container-border-radius-xs;
    border-left: none !important;
    padding: 0 0.5rem;
  }

  // Active variant
  .mat-button-toggle-checked {
    background-color: mat.m2-get-color-from-palette(pal.$app-primary, 100) !important;
    color: mat.m2-get-color-from-palette(pal.$app-primary, 900) !important;
  }

  .mat-button-toggle-label-content {
    @include mix.text-sm;
    line-height: 2rem;
  }
}

/***
  * Large toggle button
  */
.large-toggle-group {
  @include mix.gap-sm;
  border: none;

  .mat-button-toggle-label-content {
    line-height: 2rem;
  }

  // Active variant
  .mat-button-toggle-checked {
    border: 2px solid mat.m2-get-color-from-palette(pal.$app-primary, 500) !important;
    background-color: mat.m2-get-color-from-palette(pal.$app-primary, 100) !important;
    color: mat.m2-get-color-from-palette(pal.$app-primary, 800) !important;

    .large-toggle {
      &__title {
        @include mix.primary-color(500);
      }
    }
  }
}

.large-toggle {
  @include mix.center;
  @include mix.container-border-radius-xs;
  @include mix.grey-background(100);
  border-left: none !important;
  padding: 1rem;
  flex-direction: column;
  max-width: 22rem;
  cursor: pointer;

  &:hover &__title {
    @include mix.primary-color(500);
  }

  &__title {
    @include mix.grey-color(500);
    @include mix.gap-sm;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__description {
    @include mix.grey-color(500);
    @include mix.text-xs;
    text-align: start;

    span {
      white-space: normal;
      line-height: normal;
    }
  }
}

// Common ripple effect
.mat-ripple {
  border-radius: 0.5rem;
}
