@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'src/scss/utils/mixins' as mix;
@use 'sass:map';

.mat-mdc-chip:not(.mat-mdc-chip-selected) {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 300);

  // Text color
  .mdc-evolution-chip__text-label {
    color: mat.m2-get-contrast-color-from-palette(pal.$primary-palette, 50) !important;
  }
}

.mat-mdc-chip {
  padding: 0 0.4rem;
  height: 1.6rem !important;

  // Text color
  .mdc-evolution-chip__text-label {
    @include mix.text-sm;
    color: mat.m2-get-contrast-color-from-palette(pal.$primary-palette, 500) !important;
  }

  // Remove button (if present)
  .mat-mdc-chip-remove {
    color: mat.m2-get-contrast-color-from-palette(pal.$primary-palette, 50) !important;
    opacity: 1;
    transition: all 0.2s;

    &:hover {
      opacity: 0.5;
    }
  }

  // Secondary variant
  & + .chip--secondary {
    background-color: mat.m2-get-color-from-palette(pal.$primary-palette, 100) !important;

    .mdc-evolution-chip__text-label {
      color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    }

    .mat-mdc-chip-remove {
      color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    }
  }

  // Outline variant
  &.chip--outline {
    background-color: transparent !important;
    border: 1px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;

    .mdc-evolution-chip__text-label {
      color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    }

    .mat-mdc-chip-remove {
      color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    }
  }
}

// Status chips for the tables
.chip-status {
  @include mix.row;
  @include mix.vertical-center;
  @include mix.gap-sm;
  @include mix.text-xs;
  @include mix.container-border-radius-sm;
  @include mix.medium;
  padding: 0.3rem 0.6rem;
  width: fit-content;

  &--active {
    @include mix.grey-color(800);
    @include mix.success-background(200);
  }

  &--pending {
    @include mix.grey-color(800);
    @include mix.warn-background(200);
  }

  &--finished {
    @include mix.grey-color(800);
    @include mix.grey-background(300);
  }

  &--cancelled {
    @include mix.white-color();
    @include mix.error-background(600);
  }

  &--penalty {
    @include mix.white-color();
    @include mix.error-background(600);
  }

  &--paid {
    @include mix.grey-color(800);
    @include mix.success-background(200);
  }

  &--rejected {
    @include mix.grey-color(800);
    @include mix.grey-background(300);
  }

  &--validated {
    @include mix.grey-color(800);
    @include mix.primary-background(100);
  }

  &--unpaid {
    @include mix.warn-background(500);
    height: min-content;
  }

  &--bill-pending {
    @include mix.grey-color(800);
    @include mix.warn-background(500);
  }

  &--bill-accepted {
    @include mix.grey-color(800);
    @include mix.success-background(200);
  }

  &--standard {
    @include mix.grey-color(800);
    @include mix.primary-background(100);
  }

  &--refund {
    @include mix.grey-color(800);
    @include mix.grey-background(300);
  }

  &--individual-payment {
    @include mix.grey-color(800);
    @include mix.primary-background(50);
  }

  &--pending-payment {
    @include mix.grey-color(800);
    @include mix.warn-background(500);
  }
}

.category-chip {
  @include mix.container-border-radius-sm;
  @include mix.padding-xs;
  @include mix.medium;
  @include mix.horizontal-center;
  height: 1rem;
  flex-shrink: 0;

  &--standard {
    color: pal.$cat-standard !important;
    background: linear-gradient(135deg, pal.$cat-standard-bg-1 0%, pal.$cat-standard-bg-2 100%) !important;
  }

  &--comfort {
    color: pal.$cat-comfort !important;
    background: linear-gradient(135deg, pal.$cat-comfort-bg-1 0%, pal.$cat-comfort-bg-2 100%) !important;
  }

  &--premium {
    color: pal.$cat-premium !important;
    background: linear-gradient(135deg, pal.$cat-premium-bg-1 0%, pal.$cat-premium-bg-2 100%) !important;
  }

  &--unspecified {
    color: mat.m2-get-color-from-palette(pal.$app-error, 500) !important;
    background: white;
    flex-grow: 2;
  }
}

.operator-status-chip {
  @include mix.container-border-radius-sm;
  @include mix.padding-xs;
  @include mix.medium;
  height: 1rem;
  flex-shrink: 0;
  //background: white !important;
  color: pal.$dark-primary-text !important;

  &--active {
    box-shadow: 0 0 0 2px pal.$op-cat-active !important;
  }

  &--disabled {
    box-shadow: 0 0 0 2px pal.$op-cat-disabled !important;
  }

  &--pending {
    box-shadow: 0 0 0 2px pal.$op-cat-pending !important;
  }
}

.status {
  &--active {
    @include mix.success-color(500);
    filter: drop-shadow(0px 0px 1px mat.m2-get-color-from-palette(pal.$success-palette, 500));
  }

  &--pending {
    @include mix.warn-color(500);
    filter: drop-shadow(0px 0px 1px mat.m2-get-color-from-palette(pal.$pending-palette, 500));
  }

  &--inactive {
    @include mix.grey-color(500);
    filter: drop-shadow(0px 0px 1px mat.m2-get-color-from-palette(pal.$grey-palette, 500));
  }
}

.floating-chip {
  @include mix.row;
  @include mix.vertical-center;
  @include mix.gap-sm;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  z-index: 1;
  height: 1.7rem;
  padding: 2px 15px;
  border-radius: 10px;

  &__icon {
    font-size: 23px;
  }
}
