@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;

.admonition {
  @include mix.row;
  @include mix.container-border-radius-sm;
  @include mix.gap;
  @include mix.padding-sm;
  @include mix.primary-background(100);
  align-items: center;

  &__icon {
    font-size: 1.7rem;
  }

  &__text {
    @include mix.grey-color(800);
  }

  &--info {
    @include mix.grey-background(100);

    .admonition__icon {
      @include mix.primary-color(500);
    }
  }

  &--warning {
    @include mix.warn-background(100);

    .admonition__icon {
      @include mix.error-color(500);
      font-size: 2.5rem;
    }
  }
}
