@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'src/scss/utils/mixins' as mix;

// Dynamic table reset overrides
::ng-deep {
  ng-dynamic-http-table > div {
    box-shadow: none !important;

    .mat-mdc-table {
      @include mix.shadow-1;
      border-radius: 1rem !important;
      padding: 0.5rem 0.5rem 1rem 0.5rem;
    }

    .mdc-data-table {
      &__table {
        min-width: 0;
      }
    }

    .mat-mdc-header-row {
      min-height: 2.5rem !important;
    }

    // Remove background from table rows to show border radius
    .mat-mdc-header-row,
    .mat-mdc-row {
      background: transparent;
    }

    // Header cells
    .mat-mdc-header-cell {
      @include mix.grey-color(400);
      font-size: 0.85rem;
      font-weight: 600;

      // Show the sort icon when hovered or when sorter is active
      &:hover,
      .mat-sort-header-sorted {
        .mat-sort-header-arrow {
          display: block;
        }
      }

      // Hide by default
      .mat-sort-header-arrow {
        display: none;
      }

      .mat-sort-header-content {
        text-align: start;
      }
    }

    // Data cells
    .mat-mdc-cell {
      @include mix.grey-color(700);
      font-size: 0.9rem;
    }

    // Remove background from paginator
    .mat-mdc-paginator {
      background: transparent;

      .mat-mdc-paginator-page-size {
        display: none;
      }
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      border-color: mat.m2-get-color-from-palette(pal.$grey-palette, 200) !important;
    }
  }

  .mat-column-status {
    display: flex;
    justify-content: center;

    //TODO Center status header from user manage table with column values
  }

  $menu-button-size: 1.3rem;

  /** Reset menu button styles from dynamic tables **/
  .mat-column-menu {
    .mat-mini-fab {
      @include mix.grey-color(800);
      font-size: $menu-button-size;
      width: $menu-button-size;
      height: $menu-button-size;
      box-sizing: content-box;
      padding: 0.5rem;

      &:hover {
        opacity: 0.8;
      }

      // Hide ripple effect
      .mat-mdc-button-persistent-ripple,
      .mat-mdc-button-ripple {
        opacity: 0;
      }
    }
  }

  .center-column {
    justify-content: center !important;
    display: flex !important;
  }

  .mat-row {
    @include mix.gt-xs {
      pointer-events: none;
    }

    @include mix.md {
      pointer-events: all;
      &:hover,
      &:active {
        @include mix.grey-background;
      }
    }
  }

  .mat-mdc-paginator-icon {
    @include mix.md {
      transform: scale(2);
    }
  }
}

/***
  * Global columns style
  * Applied to all tables as they keep certain columns in common
 */

// Main column of the table
.mat-column-name {
  @include mix.semi-bold;
}

.mat-column-menu {
  flex: 0 0 1.5rem;
}

.mat-column-progress {
  .progress-bar {
    @include mix.row;
    @include mix.gap-sm;
    align-items: center;

    &--danger {
      @include mix.semi-bold;
    }

    &__completed {
      @include mix.text-xs;
      @include mix.success-color(800);
    }
  }

  .progress-number {
    @include mix.text-sm;
    margin-right: 0.5rem;
  }
}

/**
  * Custom styles used in several tables
  */
.main-column {
  @include mix.vertical-center;
  @include mix.gap-xs;
  @include mix.primary-color(800);
  @include mix.semi-bold;

  a {
    @include mix.primary-color(800);
    @include mix.semi-bold;
    text-decoration: none;
  }
}

.column__icon {
  width: 20px !important;
  height: 20px !important;
  flex-basis: auto;
  flex-shrink: 0;

  &--dark {
    @include mix.primary-color(500);
    @include mix.normal-weight;
  }

  &--light {
    @include mix.primary-color(200);
  }
}

.column__price {
  @include mix.semi-bold;
  @include mix.grey-color(800);
}

.column__paragraph {
  @include mix.hide-text-with-ellipsis(1.5rem, 1);
  max-width: max-content;
}

.mat-mdc-raised-button:not(:disabled) {
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 800) !important;
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  padding: 0.3125rem 0.625rem !important;
}

.mdc-button.mat-primary:hover:enabled {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
}

.mat-mdc-raised-button:hover:enabled {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
}

.mat-mdc-raised-button.disabled {
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 800) !important;
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  padding: 0.3125rem 0.625rem !important;
  opacity: 0.4;
  cursor: default !important;
}

.mdc-button.mat-primary:hover.disabled {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 800) !important;
}

.pointer {
  cursor: pointer;
}
