/***
  * Inside this file there are all the HTML shortcut classes to reduce
  * the amount of additional CSS classes. It's preferable not to combine these
  * classes if there are an existing class created to easily overview the styles
  * of a element without having to look both in the HTML and CSS files.
  *
  * Example:
  *   <div class="row vertical-center horizontal-end gap">...</div>
 */

@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/utils/screen' as screen;
@use 'sass:map';
@use '@angular/material' as mat;

.column {
  @include mix.column;
}

.column-md {
  @include screen.md {
    flex-direction: column !important;
  }
}

.column-sm {
  @include screen.sm {
    flex-direction: column !important;
  }
}

.column-xs {
  @include screen.xs {
    flex-direction: column !important;
  }
}

.row {
  @include mix.row;
}

.row-sm {
  @include screen.sm {
    flex-direction: row;
  }
}

.row-xs {
  @include screen.xs {
    flex-direction: row !important;
  }
}

.gap-lg {
  @include mix.gap-lg;
}

.gap-ml {
  @include mix.gap-ml;
}

.gap {
  @include mix.gap;
}

.gap-sm {
  @include mix.gap-sm;
}

.gap-xs {
  @include mix.gap-xs;
}

.center {
  @include mix.center;
}

.fill {
  width: 100%;
}

.fill-vertical-horizontal {
  width: 100%;
  height: 100%;
}

.fill-xs {
  @include mix.xs {
    width: 100%;
  }
}

.fill-sm {
  @include mix.sm {
    width: 100%;
  }
}

.padding {
  @include mix.padding;
}

.padding-sm {
  @include mix.padding-sm;
}

.padding-xs {
  @include mix.padding-xs;
}

.padding-lg {
  @include mix.padding-lg;
}

.padding-xl {
  @include mix.padding-xl;
}

.main-padding {
  @include mix.main-padding;
}

.hide-xs {
  @include screen.xs {
    display: none !important;
  }
}

.hide-sm {
  @include screen.sm {
    display: none !important;
  }
}

.hide-md {
  @include screen.md {
    display: none !important;
  }
}

.hide-lg {
  @include screen.gt-xs {
    display: none !important;
  }
}

.display-xs {
  display: none !important;

  @include screen.xs {
    display: flex !important;
  }
}

.display-sm {
  display: none !important;

  @include screen.sm {
    display: flex !important;
  }
}

.display-md {
  display: none !important;

  @include screen.md {
    display: flex !important;
  }
}

.box-sizing-sm {
  @include screen.sm {
    box-sizing: border-box;
  }
}

/***
  * Alignments
  */

.horizontal-start {
  @include mix.horizontal-start;
}

.horizontal-start-sm {
  @include screen.sm {
    justify-content: flex-start !important;
  }
}

.horizontal-center {
  @include mix.horizontal-center;
}

.horizontal-end {
  @include mix.horizontal-end;
}

.horizontal-space-between {
  @include mix.horizontal-space-between;
}

.vertical-end {
  @include mix.vertical-end;
}

.vertical-center {
  @include mix.vertical-center;
}

.vertical-start {
  @include mix.vertical-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grow {
  display: flex;
  flex-grow: 1;
}

.no-shrink {
  flex-shrink: 0;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

/***
  * Font displays
  */
.bold {
  @include mix.bold;
}

.heavy {
  @include mix.heavy;
}

.semi-bold {
  @include mix.semi-bold;
}

.italic {
  @include mix.italic;
}

.medium {
  @include mix.medium;
}

.font-size-2 {
  font-size: 1.2em;
}

.font-size-5 {
  font-size: 1.5em;
}

.text-xs {
  @include mix.text-xs;
}

.text-sm {
  @include mix.text-sm;
}

.text-md {
  @include mix.text-md;
}

.text-lg {
  @include mix.text-lg;
}

.text-xl {
  @include mix.text-xl;
}

.text-xxl {
  @include mix.text-xxl;
}

.image-icon-xs {
  width: 0.5rem;
}

.image-icon-sm {
  width: 1rem;
}

.image-icon-md {
  width: 1.25rem;
}

.image-icon-lg {
  width: 1.5rem;
}

.image-icon-xl {
  width: 2rem;
}

.icon-md {
  font-size: 1.5rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 2.7rem;
}

/**
  * Text alignments
 */
.justify {
  @include mix.justify;
}

.word-break {
  word-break: break-all;
}

/***
  * Palette utils
  */
//Primary color
.primary-color-900 {
  @include mix.primary-color(900);
}
.primary-color-800 {
  @include mix.primary-color(800);
}
.primary-color-600 {
  @include mix.primary-color(600);
}
.primary-color-500 {
  @include mix.primary-color(500);
}
.primary-color-300 {
  @include mix.primary-color(300);
}
.primary-color-400 {
  @include mix.primary-color(400);
}
.primary-color-200 {
  @include mix.primary-color(200);
}
.primary-color-100 {
  @include mix.primary-color(100);
}
.primary-color-50 {
  @include mix.primary-color(50);
}

// Primary background
.primary-background-900 {
  @include mix.primary-background(900);
}
.primary-background-800 {
  @include mix.primary-background(800);
}
.primary-background-700 {
  @include mix.primary-background(700);
}
.primary-background-500 {
  @include mix.primary-background(500);
}
.primary-background-400 {
  @include mix.primary-background(400);
}
.primary-background-200 {
  @include mix.primary-background(200);
}
.primary-background-100 {
  @include mix.primary-background(100);
}
.primary-background-50 {
  @include mix.primary-background(50);
}

// Black color
.black-color {
  color: black;
}

// White color
.white-color {
  color: white;
}

// Secondary color
.secondary-color-500 {
  @include mix.secondary-color(500);
}

// Tertiary color
.tertiary-color-500 {
  @include mix.tertiary-color(500);
}

// Grey color

.grey-color-800 {
  @include mix.grey-color(800);
}

.grey-color-700 {
  @include mix.grey-color(700);
}

.grey-color-500 {
  @include mix.grey-color(500);
}

.grey-color-400 {
  @include mix.grey-color(400);
}

.warn-color-500 {
  @include mix.warn-color(500);
}

.warn-color-800 {
  @include mix.warn-color(800);
}

.warn-background-200 {
  @include mix.warn-background(200);
}

.error-color-300 {
  @include mix.error-color(300);
}

.error-color-500 {
  @include mix.error-color(500);
}

.error-color-600 {
  @include mix.error-color(600);
}

.error-background-100 {
  @include mix.error-background(100);
}

.success-color-500 {
  @include mix.success-color(500);
}
.grey-background-200 {
  @include mix.grey-background(200);
}
.grey-background-100 {
  @include mix.grey-background(100);
}
.grey-background-50 {
  @include mix.grey-background(50);
}
.background-white {
  background-color: white;
}
// Borders
.primary-border-700-solid-1 {
  @include mix.primary-border(500, solid, 1px);
}
.primary-border-500-solid-1 {
  @include mix.primary-border(500, solid, 1px);
}
.primary-border-400-solid-1 {
  @include mix.primary-border(400, solid, 1px);
}
.primary-border-100 {
  @include mix.primary-border(100, solid, 1px);
}
.primary-border-100-solid-2 {
  @include mix.primary-border(100, solid, 2px);
}
.primary-border-500-dashed-1 {
  @include mix.primary-border(500, dashed, 1px);
}
.primary-border-400-dashed-1 {
  @include mix.primary-border(400, dashed, 1px);
}
.primary-outline {
  @include mix.primary-outline;
}
.shadow-1 {
  @include mix.shadow-1;
}

.shadow-2 {
  @include mix.shadow-2;
}

.container-border-radius {
  @include mix.container-border-radius;
}

.container-border-radius-xs {
  @include mix.container-border-radius-xs;
}

.container-border-radius-sm {
  @include mix.container-border-radius-sm;
}

.spinner {
  @include mix.spinner;
}

.margin-top {
  margin-top: 1rem;
}

.margin-top-sm {
  margin-top: 0.5rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-sm {
  margin-bottom: 0.5rem;
}

.margin-right {
  margin-right: 1rem;
}

.margin-right-sm {
  margin-right: 0.5rem;
}

.margin-left {
  margin-left: 1rem;
}

.saving-changes {
  @include mix.saving-changes;
}

.pre-wrap {
  white-space: pre-wrap;
}

.fit-content {
  width: fit-content;
}

.text-align-center {
  text-align: center;
}

// Applied to containers with max-width: 50rem / 800px
.container-width-50 {
  width: 100%;
  max-width: 50rem;
}

/**
 * Box to display details for reviews, total trips...
 */
.insight {
  @include mix.grey-background(200);
  @include mix.column;
  @include mix.horizontal-start;
  @include mix.container-border-radius;
  @include mix.padding-sm;
}
