@use '@angular/material' as mat;
@use 'sass:map';
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;

/**
  * Main button style
 */
%button {
  border-radius: 30px !important;
  padding: 1.3rem 1.3rem !important;
  font-size: 1rem;
  gap: 0.5rem;

  @include mix.sm {
    padding: 1.5rem 1.2rem !important;
    font-size: 1.125rem;
  }

  // Align buttons icons
  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    @include mix.heavy;

    ion-icon {
      font-size: 1.4em;
    }
  }
}

.btn,
.mdc-button,
.mat-mdc-button {
  @extend %button;

  // Disabled buttons
  &:disabled,
  .mat-button-disabled,
  &--disabled {
    opacity: 0.4;
    cursor: default !important;
  }

  &.mat-primary,
  &.btn--primary {
    @include mix.heavy;
    @include mix.primary-background(500);
    color: mat.m2-get-contrast-color-from-palette(pal.$primary-palette, 800) !important;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$primary-palette, 600) !important;
    }
  }

  &.mat-secondary,
  &.btn--secondary {
    color: mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    border: mat.m2-get-color-from-palette(pal.$primary-palette, 500) 1px solid;
    background-color: white;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 50) !important;
      border: mat.m2-get-color-from-palette(pal.$primary-palette, 600) 1px solid;
      color: mat.m2-get-color-from-palette(pal.$primary-palette, 600) !important;
    }
  }

  &.btn--secondary-color {
    @include mix.heavy;
    color: white !important;
    background-color: mat.m2-get-color-from-palette(pal.$secondary-palette, 500) !important;
  }

  &.btn--tertiary {
    @include mix.heavy;
    @include mix.primary-color(800);
    background-color: mat.m2-get-color-from-palette(pal.$tertiary-palette, 500) !important;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$tertiary-palette, 600) !important;
    }
  }

  &.btn--black {
    color: pal.$dark-primary-text 1px solid;
    border: pal.$dark-primary-text 1px solid;
    background-color: white;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 50) !important;
      border: pal.$dark-primary-text 1px solid;
      color: pal.$dark-primary-text 1px solid;
    }
  }

  &.btn--grey {
    @include mix.grey-background(100);
    border: mat.m2-get-color-from-palette(pal.$grey-palette, 300) 1px solid;
    color: mat.m2-get-color-from-palette(pal.$grey-palette, 500) !important;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
    }
  }

  &.mat-error,
  &.btn--destructive {
    background-color: mat.m2-get-color-from-palette(pal.$error-palette, 500);
    color: mat.m2-get-contrast-color-from-palette(pal.$error-palette, 500) !important;

    &:hover:enabled {
      background-color: mat.m2-get-color-from-palette(pal.$error-palette, 600);
    }
  }

  &.btn--dropdown {
    @include mix.text-sm;
    @include mix.grey-background(100);
    @include mix.grey-color(700);
    border-radius: 0.5rem !important;
  }

  /**
  * Button sizes: large, small
  */
  &.btn--lg {
    height: 2.8rem;
    padding: 0 1.25rem !important;
    min-width: 9rem;
  }

  &.btn--sm {
    height: 2rem;
    padding: 0.2rem 0.8rem !important;
  }

  &--lg-mobile {
    @include mix.sm {
      height: 3.75rem !important;
      padding: 0 1.25rem !important;
    }
  }

  /**
    Extend button to fill the container
  */
  &.btn--block {
    width: 100%;
  }
}

.mobile-btn {
  @include mix.primary-color(100);
  @include mix.primary-background(900);
  @include mix.primary-border(400, solid, 1px);
  @include mix.container-border-radius-xs;
  width: 50px;
  height: 50px;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  &--light {
    @include mix.grey-background(50);
    @include mix.primary-color(800);
    border: none;

    &:hover,
    &:active {
      @include mix.grey-background(200);
    }
  }
}

.text-btn {
  @include mix.row;
  @include mix.gap-sm;
  @include mix.primary-color(800);
  @include mix.heavy;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-decoration: none;

  @include mix.sm {
    padding: 1rem 1rem;
  }

  &:hover,
  &:active {
    @include mix.primary-color(500);
  }

  &--secondary {
    @include mix.grey-color(500);

    &:hover,
    &:active {
      @include mix.grey-color(700);
    }
  }

  &--white {
    color: white;

    &:hover,
    &:active {
      @include mix.grey-color(300);
    }
  }

  &--grey {
    @include mix.grey-background;

    &:hover,
    &:active {
      @include mix.primary-color(500);
      @include mix.grey-background(200);
    }
  }

  &--danger {
    @include mix.error-color(600);

    &:hover,
    &:active {
      @include mix.error-color(300);
    }
  }

  &--danger-light {
    @include mix.error-color(200);

    &:hover,
    &:active {
      @include mix.error-color(100);
    }
  }

  &--sm {
    @include mix.text-sm;
  }

  &--xs {
    @include mix.text-xs;
  }

  &__table {
    @include mix.horizontal-end;
    @include mix.vertical-center;
    @include mix.gap-xs;
    font-family: 'AspiraRegular', sans-serif;
    border: none !important;

    &--disabled {
      opacity: 0.4;
      cursor: default !important;
    }
  }

  &--disabled {
    opacity: 0.4;
    cursor: default !important;
    pointer-events: none;
  }
}

.dots-button {
  @include mix.grey-color(800);
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    @include mix.grey-color(500);
  }
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 500);
}

.info-button {
  @include mix.primary-background(50);
  @include mix.primary-color(800);
  @include mix.vertical-center;
  @include mix.container-border-radius-xs;
  @include mix.semi-bold;
  @include mix.gap-xs;
  cursor: pointer;
  padding: 3px 6px 3px 6px;
  text-decoration: none;

  @include mix.sm {
    padding: 5px 8px 5px 8px;
  }

  &:hover {
    @include mix.primary-background(100);
  }
}

.hide-icon {
  display: none !important;
}
