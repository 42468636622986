@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'src/scss/utils/mixins' as mix;

// Angular tabs overrides
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  @include mix.container-border-radius-xs;
  flex-grow: 0 !important;
  height: 2.5rem;

  @include mix.sm {
    height: 3.25rem;
  }

  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab:hover {
    border-radius: 0.5rem !important;
    background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
  }
}

.label-icon {
  margin-right: 0.5rem;
}

.label-data {
  @include mix.primary-color(400);
}

.mdc-tab {
  @include mix.center;
  padding: 0.5rem 1.25rem !important;

  &__text-label {
    @include mix.semi-bold;
    @include mix.text-sm;
    @include mix.gap-sm;
    color: mat.m2-get-color-from-palette(pal.$grey-palette, 800) !important;

    & ion-icon {
      @include mix.text-lg;
      color: mat.m2-get-color-from-palette(pal.$grey-palette, 500) !important;
      --ionicon-stroke-width: 2.5rem;
    }
  }
}

.mat-mdc-tab-labels {
  @include mix.gap-sm;
  @include mix.padding-xxs;
  @include mix.container-border-radius-xs;
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 200) !important;
  width: fit-content;

  @include mix.sm {
    padding-top: 0.25rem !important;
  }
}

.mat-mdc-tab-header {
  margin-bottom: 1rem;
}

.mat-mdc-tab-body-wrapper {
  @include mix.container-border-radius;
  @include mix.shadow-1;
  border-radius: 1rem !important;
}

.mdc-tab-indicator {
  &--active {
    @include mix.shadow-1;
    background-color: white !important;

    .mdc-tab__text-label {
      @include mix.bold;
    }
  }

  &__content--underline {
    border-top-style: none !important;
  }
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0 !important;
}
