@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;

.mat-accordion .mat-expansion-panel {
  border-radius: 20px !important;
}
.mat-expansion-panel {
  @include mix.shadow-1;
  @include mix.grey-color(800);
  @include mix.padding-xs;
  margin-bottom: 1rem;
}

.mat-expansion-panel-header-title {
  @include mix.grey-color(800);
  @include mix.demi;
}

.mat-expansion-indicator::after {
  color: mat.m2-get-color-from-palette(pal.$primary-palette, 800) !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  @include mix.white-background;
}
