@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;
@use 'sass:map';

pagination-template {
  ul.ngx-pagination {
    @include mix.gap-sm;
    @include mix.horizontal-center;
    text-align: center;
    width: 100%;
    padding: 0;

    li {
      @include mix.container-border-radius-xs;
      width: 25px;
      height: 25px;
      font-weight: 500;

      @include mix.sm {
        width: 2.5rem;
        height: 2.5rem;
      }

      span {
        @include mix.column;
        height: 100%;
        justify-content: center;
        color: mat.m2-get-color-from-palette(pal.$primary-palette, 500);
      }

      a {
        @include mix.grey-color(800);
      }

      border: 1px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500);

      &.pagination-next {
        display: none;
      }

      &.pagination-previous {
        display: none;
      }

      a {
        padding: 0;
        height: 100%;

        &:hover {
          @include mix.container-border-radius-xs;
        }
      }

      &.current {
        @include mix.primary-border(500, solid, 2px);
        background: mat.m2-get-color-from-palette(pal.$grey-palette, 50);
        padding: 0;
        font-weight: 600;
        width: 23px;
        height: 23px;

        @include mix.sm {
          @include mix.primary-background(500);
          width: 2.375rem;
          height: 2.375rem;

          span {
            @include mix.white-color;
          }
        }
      }
    }
  }
}
