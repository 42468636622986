$bp-xxlarge: 2560px;
$mq-xxlarge: '(max-width: #{$bp-xxlarge})';

$bp-xlarge: 1920px;
$mq-xlarge: '(max-width: #{$bp-xlarge})';

$bp-large: 1500px;
$mq-large: '(max-width: #{$bp-large})';
$mq-large-height: '(max-height: #{$bp-large})';

$bp-medium: 1280px;
$mq-medium: '(max-width: #{$bp-medium})';
$mq-medium-height: '(max-height: #{$bp-medium})';

$bp-small: 960px;
$mq-small: '(max-width: #{$bp-small})';
$mq-small-height: '(max-height: #{$bp-small})';

$bp-xsmall: 599px;
$mq-xsmall: '(max-width: #{$bp-xsmall})';

// Small devices
@mixin xs {
  @media #{$mq-xsmall} {
    @content;
  }
}

@mixin gt-xs {
  @media (min-width: #{$bp-xsmall}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media #{$mq-small} {
    @content;
  }
}

@mixin sm-height {
  @media #{$mq-small-height} {
    @content;
  }
}

// Medium devices
@mixin md {
  @media #{$mq-medium} {
    @content;
  }
}

@mixin md-height {
  @media #{$mq-medium-height} {
    @content;
  }
}

@mixin gt-md {
  @media (min-width: #{$bp-medium}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media #{$mq-large} {
    @content;
  }
}

@mixin lg-height {
  @media #{$mq-large-height} {
    @content;
  }
}

@mixin gt-lg {
  @media (min-width: #{$mq-large}) {
    @content;
  }
}

// Large devices
@mixin xl {
  @media #{$mq-xlarge} {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media #{$mq-xxlarge} {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen + 'px') {
    @content;
  }
}
