/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
@use 'palette.scss' as pal;
@use '@angular/material' as mat;
@use 'utils/mixins' as mix;
@use 'theme';

@import 'components/buttons';
@import 'components/forms';
@import 'components/chips';
@import 'components/tables';
@import 'components/displays';
@import 'components/select';
@import 'components/modals';
@import 'components/snackbar';
@import 'components/paginator';
@import 'components/admonitions';
@import 'components/progress-bar';
@import 'components/skeletons';
@import 'components/accordion';
@import 'components/toggle-buttons';
@import 'components/tabs';
@import 'components/calendar';
@import 'components/wordpress';

@font-face {
  font-family: AspiraHeavy;
  src: url(/assets/fonts/Aspira-Heavy.ttf) format('truetype');
}
@font-face {
  font-family: AspiraRegular;
  src: url(/assets/fonts/Aspira-Regular.ttf) format('truetype');
}
@font-face {
  font-family: AspiraDemi;
  src: url(/assets/fonts/Aspira-Demi.ttf) format('truetype');
}
@font-face {
  font-family: AspiraBlack;
  src: url(/assets/fonts/Aspira-Black.ttf) format('truetype');
}
@font-face {
  font-family: AspiraThin;
  src: url(/assets/fonts/Aspira-Thin.ttf) format('truetype');
}

/** Remove default margin for layout purposes */
.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  margin-bottom: 0;
  @include mix.heavy;
  font-size: 28px;

  @include mix.xs {
    font-size: 20px;
  }
}

html,
body {
  min-height: 100%;
  height: 100%;
}
.mat-typography h1 {
  @include mix.heavy;
  font-size: 32px;
}

.mat-typography h2 {
  @include mix.demi;
  font-size: 30px;
}

.mat-typography h4 {
  @include mix.heavy;
  font-size: 24px;
  margin-bottom: 0;
}

.custom-timepicker .timepicker__actions {
  @include mix.sm {
    @include mix.column;
    @include mix.gap-sm;
  }
}

/**
 * <hr/> dividers
 */
.divider {
  flex: 1;
  width: -webkit-fill-available; // Applied when flex is not available
  width: -moz-available; // Applied when flex is not available
  border: 1px solid mat.m2-get-color-from-palette(pal.$app-grey, 300);
}

body {
  height: 100vh !important;
  margin: 0 !important;
  background: mat.m2-get-color-from-palette(pal.$grey-palette, 50);
}

mat-sidenav-content {
  background: mat.m2-get-color-from-palette(pal.$grey-palette, 50) !important;
}

ngx-material-timepicker-container {
  z-index: 1000 !important;
  position: fixed;
}

// For timepickers to appear in front of dialogs
.cdk-overlay-container {
  z-index: 500 !important;
}

.client-background {
  @include mix.client-background;
}

.road-blue-background {
  @include mix.road-blue-background;
}

.common-header {
  @include mix.column;
  @include mix.center;
  @include mix.white-color;

  height: 20vh;
  margin-bottom: 1rem;
  padding-top: 5rem;
}

.pac-container {
  z-index: 100000 !important;
  position: fixed !important;
}

//Removes tooltip from ionic icons
ion-icon {
  pointer-events: none;
}
