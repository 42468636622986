@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;

.cal-cell-row:not(.cal-header):hover {
  background-color: white !important;
}

.cal-cell-row:not(.cal-header) .cal-cell:not(.cal-has-events):hover {
  background-color: white !important;
  border: 1px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
  cursor: pointer;
}

.cal-header .cal-cell:hover {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
}

.cal-cell-row .cal-cell:not(.cal-has-events):hover .event__icon--available {
  display: inline-block;
}

.cal-cell-row .cal-cell:not(.cal-has-events):hover p {
  display: none;
}

.cal-day-cell.cal-today:not(.cal-has-events) {
  background-color: white !important;
  border: 1px solid mat.m2-get-color-from-palette(pal.$grey-palette, 800) !important;
}

.cal-day-cell.cal-today.cal-has-events,
.cal-header {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
}

.cal-day-cell {
  border: 0.5px solid mat.m2-get-color-from-palette(pal.$grey-palette, 300) !important;
}

.cal-cell-row {
  min-height: 120px !important;
}

.cal-month-view .cal-header {
  height: 40px !important;
  min-height: 0 !important;
}

.cal-has-events {
  @include mix.grey-background(100);
}

.cal-has-events:hover {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, 100) !important;
}

.cal-out-month {
  opacity: 0.5;
}
