@use '@angular/material' as mat;

@include mat.core();

/**
  * Basic palette
  *
  * Default palettes are available at https://material.io/design/color/
  *
  * You can create a custom palette at http://mcg.mbitson.com/
  *
  * To determine the contrast color go to https://toolness.github.io/accessible-color-matrix/
  *
*/

/*
 * Variables used in palette
 */
$dark-primary-text: #000000;
$light-primary-text: #ffff;

$tertiary-palette: (
  300: #fbcddd,
  500: #f8b0c9,
  contrast: (
    300: $dark-primary-text,
    500: $light-primary-text
  )
);

$secondary-palette: (
  500: #78aa6d
);

$cat-standard-bg-1: #e0f6ff;
$cat-standard-bg-2: #f4fcff;
$cat-standard: #006694;
$cat-comfort-bg-1: #c8c2d6;
$cat-comfort-bg-2: #e8def8;
$cat-comfort: #5c4681;
$cat-premium-bg-1: #ffd008;
$cat-premium-bg-2: #ffe34a;
$cat-premium: #796200;

$op-cat-active: #d5fcc7;
$op-cat-pending: #f9e26d;
$op-cat-disabled: #efc3c6;

// Primary palette
/* prettier-ignore */
$primary-palette: (
  50  : #E0E7F4,
  100 : #B3C2E3, // lighter
  200 : #007CCF,
  300 : #005BC2,
  400 : #005BC2,
  500 : #0033A1, // default
  600 : #002B89,
  700 : #002470,
  800 : #002470,
  900 : #001A54,
  contrast: (
    50  : $dark-primary-text,
    100 : $dark-primary-text,  // Lighter contrast color
    200 : $dark-primary-text,
    300 : $dark-primary-text,
    400 : $dark-primary-text,
    500 : $light-primary-text, // Default contrast color
    600 : $light-primary-text,
    700 : $light-primary-text, // Darker contrast color
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

// Accent palette
/* prettier-ignore */
$accent-palette: (
  50: #e7e4ee,
  100: #f2f3f8,
  // lighter
  200: #9a8eba,
  300: #def8f7,
  400: #533e8a,
  500: #9bd4d2,
  // default
  600: #30196d,
  700: #48a9a6,
  // darker
  800: #2f8c89,
  900: #160945,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    // Lighter contrast color
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    // Default contrast color
    600: $light-primary-text,
    700: $light-primary-text,
    // Darker contrast color
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

// Warn palette
/* prettier-ignore */
$error-palette: (
  100 : #EFC3C6,
  200 : #FE6F77,
  300 : #C1666B,
  500 : #8C2228,
  600 : #81181E,  // Status: Error, cancel
  contrast:
    (
      100 : black,
      200 : black,
      300 : black,
      500 : white,
      600 : white,
    )
);

$pending-palette: (
  200: #fff6c7,
  500: #f9e26d,
  700: #f9e26d,
  800: #a58b03,
  contrast: (
    200: black,
    500: black
  )
);

$grey-palette: (
  50: #fcfcfc,
  100: #f8f8f8,
  200: #f3f3f3,
  300: #e9e9e9,
  400: #b4b4b4,
  500: #5c5c5c,
  700: #333333,
  800: #252525,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$success-palette: (
  200: #d5fcc7,
  500: #04b800,
  700: #04b800,
  contrast: (
    200: black,
    500: black,
    700: black
  )
);

$app-primary: mat.m2-define-palette($primary-palette, 500, 100, 800);
$app-accent: mat.m2-define-palette($accent-palette, 500, 300, 900);
$app-success: mat.m2-define-palette($success-palette, 500, 200, 700);
$app-warn: mat.m2-define-palette($pending-palette, 500, 200, 700);
$app-grey: mat.m2-define-palette($grey-palette, 500, 300, 800);
$app-error: mat.m2-define-palette($error-palette, 500, 100, 600);
