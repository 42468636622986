@use 'src/scss/palette.scss' as pal;
@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'sass:map';

/***
  Apply custom styles to the default Angular Material snackbar
  */
.mdc-snackbar .mdc-snackbar__surface {
  @include mix.shadow-1;
  @include mix.grey-background(199);
  border-radius: 1rem !important;
  margin-bottom: 3.5rem;
  color: black;

  // Snackbar text
  .mdc-snackbar__label {
    padding: 1rem 1.8rem !important;

    .toast-content {
      @include mix.vertical-center;
      @include mix.gap;
      font-weight: 500;
      font-size: 0.9rem;

      .toast-icon {
        font-size: 1.5rem;
      }
    }
  }
}

/*
  Info snackbar (by default)
  */
.mat-snack-bar-info .mdc-snackbar__surface {
  background-color: mat.m2-get-color-from-palette(pal.$app-grey, 100) !important;

  .mdc-snackbar__label {
    @include mix.grey-color(800);

    .toast-icon {
      @include mix.primary-color(800);
    }
  }
}

/*
  Error snackbar
  */
.mat-snack-bar-error .mdc-snackbar__surface {
  background-color: mat.m2-get-color-from-palette(pal.$app-error, 300) !important;

  .mdc-snackbar__label {
    @include mix.semi-bold;
    color: mat.m2-get-color-from-palette(pal.$app-error, 800);
  }
}

/*
  Success snackbar
  */
.mat-snack-bar-success .mdc-snackbar__surface {
  background-color: mat.m2-get-color-from-palette(pal.$app-success, 200) !important;

  .mdc-snackbar__label {
    @include mix.grey-color(800);

    .toast-icon {
      color: mat.m2-get-color-from-palette(pal.$app-success, 500);
    }
  }
}
