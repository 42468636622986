@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'src/scss/utils/mixins' as mix;
@use 'sass:map';

$hint-and-error-font-size: 0.85rem;
/**
  Angular Material overrides and default styling
  */
.mat-mdc-form-field,
.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper {
    border: none;
    border-radius: 1.25rem;
    background-color: mat.m2-get-color-from-palette(pal.$app-grey, 50) !important;
    transition: 0.1s;
    outline: transparent;
    outline-offset: -2px;
    gap: 0.1rem;
    outline: 1px solid mat.m2-get-color-from-palette(pal.$app-grey, 300) !important;

    // Form border
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border: none;
      }
    }

    // Separation between text input and icons
    .mat-mdc-form-field-flex {
      @include mix.gap-sm;
      padding: 0 0.5rem;

      // Prefix icon size
      .mat-mdc-form-field-icon-prefix {
        font-size: 1.2em;
        margin-left: 0.5rem;
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: black;
}

//This is a variant form applied for searches
.white-background {
  .mat-mdc-text-field-wrapper {
    @include mix.shadow-1;
    background-color: white !important;
  }
}

// When the input is focused
.mat-focused {
  .mat-mdc-text-field-wrapper {
    outline: 1px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    background-color: white !important;
  }
}

// Invalid
.mat-form-field-invalid {
  .mat-mdc-text-field-wrapper {
    outline: 2px solid mat.m2-get-color-from-palette(pal.$error-palette, 500) !important;
  }
}

mat-hint {
  @include mix.grey-color(500);
  font-size: $hint-and-error-font-size;
}

mat-error {
  color: mat.m2-get-color-from-palette(pal.$error-palette, 500) !important;
  font-size: $hint-and-error-font-size;
  font-weight: 800;
}

.hidden-hint {
  .mat-mdc-form-field-bottom-align {
    display: none;
  }
}

/*
  Form input sizes
  Each form should adapt his size to the expected input size
  */
.form__input {
  width: 100%;

  &.form-xxs {
    max-width: 6.25rem;
  }

  &.form-xs {
    max-width: 8rem;
  }

  &.form-sm {
    max-width: 11rem;
  }

  &.form-md {
    max-width: 17rem;
  }

  &.form-lg {
    max-width: 22.5rem;
  }

  &.form-xl {
    max-width: 28rem;
  }

  &.form-xxl {
    max-width: 34rem;
  }

  &.form-xxxl {
    max-width: 50rem;
  }

  div.mat-form-field-prefix {
    margin: 0.5rem;
  }

  &--primary > div:first-child {
    border: 2px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
    outline: none !important;

    span {
      @include mix.primary-color(500);
      @include mix.bold;
    }

    ion-icon {
      @include mix.primary-color(500);
      font-size: 1.5rem;
    }

    .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }

    &:hover {
      border: 2px solid mat.m2-get-color-from-palette(pal.$primary-palette, 300) !important;
      outline: none !important;

      span {
        @include mix.primary-color(300);
      }

      ion-icon {
        @include mix.primary-color(300);
        font-size: 1.5rem;
      }
    }
  }
}

/*
  Form Container
  Wraps all form fields, including groups/sections.
  Commonly used with form HTML tag
  */
.form-container {
  @include mix.column;
  @include mix.gap;
}

/*
  Form Wrapper
  Wraps form fields and labels in a flex container.
 */
.form-wrapper {
  @include mix.column;

  h2,
  h3 {
    color: black;
  }

  mat-label {
    @include mix.text-sm;
    font-weight: 400;
    color: black;
    padding-bottom: 0.25rem;

    &.title {
      @include mix.text-md;
      @include mix.semi-bold;
      color: black;
      padding-bottom: 0.5rem;
    }
  }

  &--white {
    h2,
    h3 {
      color: white;
    }

    mat-label {
      color: white;
    }

    &.title {
      color: white;
    }
  }
}

.dark-form-container {
  @include mix.primary-background(800);
  @include mix.padding-sm;
  @include mix.container-border-radius-sm;

  & mat-error {
    color: mat.m2-get-color-from-palette(pal.$error-palette, 200) !important;
    font-family: AspiraDemi, sans-serif;
  }

  & .mat-form-field-invalid .mat-mdc-text-field-wrapper {
    outline: 3px solid mat.m2-get-color-from-palette(pal.$error-palette, 500) !important;
  }

  &--end-tour {
    @include mix.vertical-center;
    @include mix.horizontal-space-between;
    @include mix.row;
    @include mix.md {
      @include mix.column;
      @include mix.vertical-end;
    }
    @include mix.sm {
      @include mix.vertical-center;
    }
  }
}

.form-primary {
  .mat-mdc-text-field-wrapper {
    outline: 2px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;

    &.mdc-text-field--invalid {
      outline: 2px solid mat.m2-get-color-from-palette(pal.$error-palette, 500) !important;
    }
  }

  ion-icon {
    color: mat.m2-get-color-from-palette(pal.$primary-palette, 500);
  }
}

mat-form-field.autocomplete-field {
  @include mix.white-background;
  padding: 1rem 1rem 0 1rem;
  order: -1;
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;

  &.mat-mdc-form-field .mat-mdc-text-field-wrapper,
  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    @include mix.container-border-radius;
  }

  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      @include mix.container-border-radius;
      outline: 1px solid mat.m2-get-color-from-palette(pal.$primary-palette, 500) !important;
      background-color: white !important;
    }
  }
}

mat-option.hide-option {
  display: none !important;
}

.fit-content.mat-mdc-select-panel {
  min-width: fit-content;
}
