//
// Select menu and options
// Including: select panels from autocomplete, forms, and table dropdowns
// --------------------------------------------------

@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;
@use 'sass:map';
$border-color: transparent;
$option-icon-margin: 0.8rem;

/**
  Stylize each select menu option
  */
%select-option {
  @include mix.vertical-center;
  border-bottom: $border-color solid;
  @include mix.md {
    padding: 1.25rem;
    gap: 0.65rem;
    border-bottom: 1px solid mat.m2-get-color-from-palette(pal.$grey-palette, 300);
    width: 22rem;
    top: 1rem !important;
  }

  // Stylize option icon
  ion-icon,
  i {
    @include mix.grey-color(400);
    margin-right: $option-icon-margin;
  }

  ion-icon {
    font-size: 1.2rem;
  }

  // Font awesome icons case: reduce size to keep consistency
  i {
    font-size: 1rem;
  }

  // Option container, align icon and text
  span {
    @include mix.vertical-center;
    font-size: 0.875rem;
    font-weight: 500;
  }

  // Selected option
  & + .mdc-list-item--selected {
    font-weight: 600 !important;
  }

  // Danger option: second selector applied to Dynamic Table
  &.danger-option span,
  &.danger-option button span {
    @include mix.error-color(500);

    ion-icon,
    i {
      @include mix.error-color(500);
    }
  }

  // Edit option: second selector applied to Dynamic Table
  &.edit-option span,
  &.edit-option button span {
    @include mix.grey-color(800);
  }

  // Hover option
  &:hover:not([disabled]):not(.mat-selected) {
    background: mat.m2-get-color-from-palette(pal.$app-grey, 100);

    // Colorize text
    span {
      @include mix.primary-color(800);

      ion-icon,
      i {
        @include mix.primary-color(800);
      }
    }
  }

  // Hover for danger option
  &.danger-option:hover:not([disabled]):not(.mat-selected) span,
  &.danger-option:hover:not([disabled]):not(.mat-selected) button span {
    @include mix.error-color(300);

    ion-icon,
    i {
      @include mix.error-color(300);
    }
  }

  // Removes border from last option
  &:last-child {
    border-bottom: none;
  }
}

/**
  * Angular select containers
  */
.mat-mdc-menu-panel,
.mat-mdc-select-panel,
.mat-mdc-datepicker-content,
.mat-mdc-autocomplete-panel {
  @include mix.shadow-1;

  // Each select option
  .mat-mdc-option {
    @extend %select-option;

    .semi-bold {
      @include mix.semi-bold;
    }

    .medium {
      @include mix.medium;
    }

    .normal-weight {
      @include mix.normal-weight;
    }
  }

  // Dropdown case
  .mat-mdc-menu-content {
    padding: 0 !important;
    min-width: 10rem;

    @include mix.sm {
      padding: 1rem !important;
      overflow-x: hidden;
    }

    .mat-mdc-menu-item,
    div {
      @extend %select-option;
      border-bottom: none;
    }

    // Reduce icon margin in dynamic table cells due to a excess of space
    div .mat-mdc-menu-item i {
      margin-right: $option-icon-margin - 0.5rem;
    }

    .mat-mdc-menu-item {
      padding-right: 2rem;
      @include mix.md {
        padding-right: 1.25rem;
      }
    }

    .mat-mdc-menu-item:first-of-type {
      @include mix.md {
        padding-top: 0;
      }
    }
  }

  // Set minimum height for the dropdown (empty case)
  min-height: 3rem !important;
}

.mat-mdc-menu-panel {
  @include mix.sm {
    max-width: none !important;
  }
}

.mat-mdc-select .mat-mdc-select-trigger {
  @include mix.vertical-center;
  justify-content: space-between;

  .mat-mdc-select-value {
    max-width: inherit;

    &:focus-visible {
      outline: mat.m2-get-color-from-palette(pal.$primary-palette, 800) 2px solid !important;
    }
  }

  &:focus-visible {
    outline: mat.m2-get-color-from-palette(pal.$primary-palette, 800) 2px solid !important;
  }
}

.dropdown {
  &--lg,
  &--lg + .mat-mdc-menu-panel {
    width: 14.5rem;
  }
}
