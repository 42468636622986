@use '@angular/material' as mat;
@use 'src/scss/utils/mixins' as mix;
@use 'src/scss/palette' as pal;
@use 'sass:map';

%progress-bar-base {
  @include mix.container-border-radius;
  flex-grow: 1;
}

.bar {
  @extend %progress-bar-base;

  &--primary .mdc-linear-progress__bar-inner {
    border-color: mat.m2-get-color-from-palette(pal.$primary-palette, 800) !important;
  }

  &--secondary,
  &--secondary + .mat-mdc-progress-bar {
    .mdc-linear-progress__bar-inner {
      border-color: mat.m2-get-color-from-palette(pal.$primary-palette, 700) !important;
    }

    background-color: mat.m2-get-color-from-palette(pal.$primary-palette, 300) !important;
  }

  &--md {
    height: 0.57rem !important;

    .mdc-linear-progress__bar-inner {
      // High value to avoid rescale issue
      transform: scaleY(40);
      border-color: mat.m2-get-color-from-palette(pal.$primary-palette, 800);
    }
  }

  &--xl {
    height: 1.5rem !important;

    .mdc-linear-progress__bar-inner {
      // High value to avoid rescale issue
      transform: scaleY(40);
      border-color: mat.m2-get-color-from-palette(pal.$primary-palette, 500);
    }
  }
}

/**
  * Override of the progress bar from the dynamic table component
  */
ng-dynamic-http-table {
  mat-progress-bar {
    @extend %progress-bar-base;
    height: 0.5rem !important;

    .mdc-linear-progress__bar-inner {
      transform: scaleY(40);
    }
  }

  .progress-bar--danger {
    .mdc-linear-progress__bar-inner {
      border-color: mat.m2-get-color-from-palette(pal.$error-palette, 800) !important;
    }
  }

  .progress-bar--warning {
    .mdc-linear-progress__bar-inner {
      border-color: mat.m2-get-color-from-palette(pal.$pending-palette, 600) !important;
    }
  }
}
