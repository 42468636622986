@use 'src/scss/utils/mixins' as mix;

// Override default modals
.mat-mdc-dialog-panel {
  @include mix.xs {
    padding: 1rem !important;
  }
}

.mat-mdc-dialog-surface {
  @include mix.shadow-1;
  border-radius: 1.4rem !important;
  padding: 1.8em;
  @include mix.xs {
    padding: 1rem !important;
  }
}

.modal-actions,
.mat-mdc-dialog-actions {
  @include mix.gap;
  @include mix.vertical-center;
  justify-content: flex-end !important;
  margin-top: 1rem;
  flex-wrap: nowrap !important;
  @include mix.md {
    padding: 0.25rem !important;
  }
}

.mat-mdc-dialog-content {
  // Leave a space to the right side for the scroll bar
  padding: 0 0.6rem 0.3rem 0 !important;
}

.modal-title {
  font-weight: 400 !important;
}
